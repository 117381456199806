// import '../wdyr';
import 'regenerator-runtime';
import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import theme from '@dev-togetherprice/theme';
import TPSWRConfig from '@dev-togetherprice/components.tp-swr-config';
import { I18nextProvider } from 'react-i18next';
import AppContext, {
  AppContextValue,
} from '@dev-togetherprice/components.app-context';
import i18n from '@/i18n';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import useBindChangeLocaleEvent from '@dev-togetherprice/hooks.use-bind-change-locale-event';
import useLocale from '@dev-togetherprice/hooks.use-locale';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import ErrorToast from '@/components/ErrorToast';
import TopPixelFix from '@dev-togetherprice/components.top-pixel-fix';
import AppLinearProgress from '@dev-togetherprice/components.app-linear-progress';
import { navigateToUrl as spaNavigateToUrl } from 'single-spa';
import MuiThemeProvider from '@/containers/MuiThemeProvider';
import useProcessSnapshot from '@/hooks/useProcessSnapshot';
import { SnackbarProvider } from 'notistack';
import withRecoilRoot from '@/hoc/withRecoilRoot';
import initAppState from '@/utils/initAppState';
import Routes from './Routes';

/**
 * Can be used to set up default app props in testing.
 * Example:
 *  to test public micro frontend
 *
 *  ```javascript
 *    localStorage.setItem('__MF_NETWORK_Custom_Props', JSON.stringify({
 *      public: true,
 *    }))
 *  ```
 */
const customAppPropsKey = '__MF_NETWORK_Custom_Props';
const getCustomAppProps = (): Partial<AppProps> =>
  JSON.parse(localStorage.getItem(customAppPropsKey) || '{}');

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export type AppProps = AppContextValue;
const App: React.FC<AppProps> = ({
  name,
  lng,
  basename = '/',
  navigateToUrl = spaNavigateToUrl,
  ...props
}) => {
  const processSnap = useProcessSnapshot();
  const [error, setError] = useState<string | ReactElement>('');
  const classes = useStyles();
  useBindChangeLocaleEvent();
  useLocale(lng);

  useEffect(() => () => processSnap(), [processSnap]);

  return (
    <AppContext.Provider
      value={{
        name,
        lng,
        basename,
        navigateToUrl,
        error,
        setError,
        ...props,
        ...getCustomAppProps(),
      }}
    >
      <TPSWRConfig>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Slide}
          maxSnack={1}
          autoHideDuration={5000}
        >
          <I18nextProvider i18n={i18n}>
            <ScopedCssBaseline className={classes.root}>
              <MuiThemeProvider seed={name}>
                <ErrorToast />
                <TopPixelFix />
                <Suspense fallback={<AppLinearProgress />}>
                  <Routes />
                </Suspense>
              </MuiThemeProvider>
            </ScopedCssBaseline>
          </I18nextProvider>
        </SnackbarProvider>
      </TPSWRConfig>
    </AppContext.Provider>
  );
};

export default withRecoilRoot(App, initAppState);
